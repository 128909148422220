.contentSection {
  height: fit-content;
  padding: 30px;
  background: var(--white);
  border-radius: 15px;
  //filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.16));

  @media (max-width: 768px) {
    padding: 20px;
  }
}
