.ads {
  iframe {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  :global(#container-46728ba2c593e2a08b4c757bb798d2ec) {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
