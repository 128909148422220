/* vietnamese */
@font-face {
  font-family: '__Josefin_Sans_bc8ab8';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/bdd413190225924c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Josefin_Sans_bc8ab8';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/0a82ec5629edbafa-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Josefin_Sans_bc8ab8';
  font-style: normal;
  font-weight: 100 700;
  font-display: swap;
  src: url(/_next/static/media/a3a474206a856479-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Josefin_Sans_Fallback_bc8ab8';src: local("Arial");ascent-override: 73.32%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 102.29%
}.__className_bc8ab8 {font-family: '__Josefin_Sans_bc8ab8', '__Josefin_Sans_Fallback_bc8ab8';font-style: normal
}.__variable_bc8ab8 {--font: '__Josefin_Sans_bc8ab8', '__Josefin_Sans_Fallback_bc8ab8'
}

.style_button__guPzY{min-height:45px;padding:10px 15px 6px 15px;background:var(--secondary-color);color:var(--primary-color);font-family:var(--font);font-weight:400;font-size:20px;border:none;border-radius:10px;transition:opacity var(--transition);cursor:pointer;-webkit-font-smoothing:antialiased;position:relative;display:flex;align-items:center;justify-content:center}.style_button__guPzY span{display:flex;color:var(--primary-color)}.style_button__guPzY svg{height:15px;width:15px;margin-top:-4px;margin-right:7px}.style_button__guPzY svg path{color:var(--primary-color)}.style_button__guPzY:hover{opacity:.6;transition:opacity var(--transition);cursor:pointer}.style_button__guPzY.style_buttonDisabled__h22yp{pointer-events:none;opacity:.5}.style_button__guPzY.style_buttonLoading__L547x{pointer-events:none;opacity:.7}.style_button__guPzY .style_loaderWrapper__V4aUw{top:0;position:absolute;background:var(--secondary-color);width:100%;height:100%;border-radius:10px;display:flex;justify-content:center;align-items:center}
.style_button__R6dum{background:none;border:none;font-family:var(--font);font-size:20px;cursor:pointer;text-decoration:underline;font-weight:400;transition:var(--transition)}.style_button__R6dum:hover{opacity:.6;transition:var(--transition)}.style_button__R6dum.style_buttonDisabled__dqA5z{pointer-events:none;opacity:.5}
.style_loader__5dcjT{height:9px;width:60px;--c:no-repeat linear-gradient(#000 0 0);background:var(--c),var(--c),var(--c),var(--c);background-size:26% 3px;animation:style_l3__HBuNL 1s infinite}@keyframes style_l3__HBuNL{0%,10%{background-position:0% 100%,33.3333333333% 100%,66.6666666667% 100%,100% 100%}17.5%{background-position:0% 0,33.3333333333% 100%,66.6666666667% 100%,100% 100%}25%{background-position:0% 0,33.3333333333% 0,66.6666666667% 100%,100% 100%}32.5%{background-position:0% 0,33.3333333333% 0,66.6666666667% 0,100% 100%}40%,60%{background-position:0% 0,33.3333333333% 0,66.6666666667% 0,100% 0}67.5%{background-position:0% 100%,33.3333333333% 0,66.6666666667% 0,100% 0}75%{background-position:0% 100%,33.3333333333% 100%,66.6666666667% 0,100% 0}82.5%{background-position:0% 100%,33.3333333333% 100%,66.6666666667% 100%,100% 0}90%,100%{background-position:0% 100%,33.3333333333% 100%,66.6666666667% 100%,100% 100%}}
.style_contentSection__WFeU3{height:-moz-fit-content;height:fit-content;padding:30px;background:var(--white);border-radius:15px}@media(max-width: 768px){.style_contentSection__WFeU3{padding:20px}}
.style_inputWrapper__piFKs{cursor:pointer;display:flex;flex-direction:column}.style_inputWrapper__piFKs .style_label__qVb3c{font-size:20px;margin-bottom:5px}.style_inputWrapper__piFKs .style_counter__MCHXG{font-size:16px;margin-top:5px;text-align:right}.style_inputWrapper__piFKs .style_input__2w9vb{font-family:var(--font);box-sizing:border-box;min-height:47px;width:100%;border-radius:10px;border:1px solid #fff;background:#fff;outline:none;transition:var(--transition);padding:14px;resize:none;color:var(--secondary-color)}.style_inputWrapper__piFKs .style_input__2w9vb:focus,.style_inputWrapper__piFKs .style_input__2w9vb:focus-visible{border:1px solid #ddd;transition:var(--transition)}.style_inputWrapper__piFKs .style_input__2w9vb::placeholder{opacity:.5}
.style_header__Pp4Zg{width:100%;max-width:1084px;padding:30px;display:flex;justify-content:space-between}.style_header__Pp4Zg .style_logo__IVIWQ{display:flex;align-items:center;transition:var(--transition)}.style_header__Pp4Zg .style_logo__IVIWQ:hover{opacity:.6;transition:var(--transition)}.style_header__Pp4Zg .style_navigation__m9BbV{display:flex;align-items:center}.style_header__Pp4Zg .style_navigation__m9BbV li{margin-left:25px}.style_header__Pp4Zg .style_navigation__m9BbV .style_loginBlock__5JNKc{height:30px;display:flex;align-items:center;justify-content:center}.style_header__Pp4Zg .style_navigation__m9BbV .style_loginBlock__5JNKc button{padding:3px 10px 0 10px;min-height:30px}@media(max-width: 768px){.style_header__Pp4Zg .style_navigation__m9BbV{display:none}}@media(max-width: 768px){.style_header__Pp4Zg{padding:30px 20px}}
.style_footer__2ozwy{width:100%;padding:100px 30px 30px 30px;width:100%;max-width:1084px;display:flex;justify-content:space-between}.style_footer__2ozwy p,.style_footer__2ozwy a{font-size:16px}@media(max-width: 768px){.style_footer__2ozwy{padding:50px 20px 30px 20px}}.style_footer__2ozwy .style_links___qnvY{display:flex;align-items:center}.style_footer__2ozwy .style_links___qnvY a{margin-left:20px}
:root{--primary-color: #F0EFE9;--secondary-color: #141413;--white: #ffffff;--transition: .3s }*{padding:0;margin:0;font-size:20px;font-weight:400;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:var(--secondary-color);text-underline-offset:.25em;box-sizing:border-box}html{background:var(--primary-color);min-width:360px}html,body{height:100%;scroll-behavior:smooth}body{flex-direction:column;display:flex;align-items:center;width:100%}main{flex:1 1;max-width:1084px;padding:0 30px;width:100%}@media(max-width: 768px){main{padding:0 20px}}@keyframes bgAnim{0%{transform:scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)}50%{transform:scaleX(1) scaleY(1) scaleZ(1) rotateX(-45deg) rotateY(-45deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)}100%{transform:scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)}}.bgAnimation{position:fixed;z-index:-1;pointer-events:none;display:flex;justify-content:center;align-items:center;overflow:hidden;width:100%;height:100%}.bgAnimation svg{min-width:-moz-fit-content;min-width:fit-content;max-height:100vh;animation:bgAnim 30s infinite linear}ul,li{list-style:none}h1{font-size:36px;font-weight:600}@media(max-width: 768px){h1{font-size:30px}}h2{font-size:24px;font-weight:600}@media(max-width: 768px){h2{font-size:20px}}p{font-size:20px;line-height:125%}@media(max-width: 768px){p{font-size:18px;line-height:120%}}a{text-decoration:none}
.style_ads__SmrMD iframe{opacity:0;pointer-events:none;position:fixed;bottom:0;left:0;z-index:-1}.style_ads__SmrMD #container-46728ba2c593e2a08b4c757bb798d2ec{opacity:0;pointer-events:none;position:fixed;bottom:0;left:0;z-index:-1}
.style_heroBlock__PvUqP{margin-top:30px;margin-bottom:35px;padding-bottom:35px;border-bottom:1px dashed var(--secondary-color)}.style_heroBlock__PvUqP h1{margin-bottom:22px}.style_heroBlock__PvUqP p{max-width:700px;margin-bottom:22px}.style_heroBlock__PvUqP h2 a{font-size:inherit;font-weight:inherit}
.style_heroBlock__9SSXz{margin-top:30px;margin-bottom:35px;padding-bottom:35px;border-bottom:1px dashed var(--secondary-color)}.style_heroBlock__9SSXz h1{margin-bottom:22px}.style_heroBlock__9SSXz p{max-width:700px}.style_plans__EZvfV .style_plansDescription__Z2alG h2{margin-bottom:22px}.style_plans__EZvfV .style_plansDescription__Z2alG p{margin-bottom:35px;max-width:800px}.style_plans__EZvfV .style_plansBlock__zOO3x{display:flex}.style_plans__EZvfV .style_plansBlock__zOO3x h2{margin-bottom:22px}.style_plans__EZvfV .style_plansBlock__zOO3x .style_planBlock__rEieh{width:50%}.style_plans__EZvfV .style_plansBlock__zOO3x .style_planBlock__rEieh:first-child{margin-right:15px}.style_plans__EZvfV .style_plansBlock__zOO3x .style_planBlock__rEieh:last-child{margin-left:15px}
