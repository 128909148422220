.button {
  min-height: 45px;
  padding: 10px 15px 6px 15px;
  background: var(--secondary-color);
  color: var(--primary-color);
  font-family: var(--font);
  font-weight: 400;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  transition: opacity var(--transition);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    color: var(--primary-color);
  }

  svg {
    height: 15px;
    width: 15px;
    margin-top: -4px;
    margin-right: 7px;

    path {
      color: var(--primary-color);
    }
  }

  &:hover {
    opacity: 0.6;
    transition: opacity var(--transition);
    cursor: pointer;
  }

  &.buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.buttonLoading {
    pointer-events: none;
    opacity: 0.7;
  }

  .loaderWrapper {
    top: 0;
    position: absolute;
    background: var(--secondary-color);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
