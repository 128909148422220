.inputWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .counter {
    font-size: 16px;
    margin-top: 5px;
    text-align: right;
  }
  .input {
    font-family: var(--font);
    box-sizing: border-box;
    min-height: 47px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid white;
    background: white;
    outline: none;
    transition: var(--transition);
    padding: 14px;
    resize: none;
    color: var(--secondary-color);

    &:focus, &:focus-visible {
      border: 1px solid #dddddd;
      transition: var(--transition);
    }

    &::placeholder {
      opacity: 0.5;
    }
  }
}
