.button {
  background: none;
  border: none;
  font-family: var(--font);
  font-size: 20px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  transition: var(--transition);

  &:hover {
    opacity: 0.6;
    transition: var(--transition);
  }

  &.buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
