.heroBlock {
  margin-top: 30px;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px dashed var(--secondary-color);

  h1 {
    margin-bottom: 22px;
  }
  p {
    max-width: 700px;
  }
}

.plans {
  .plansDescription {
    h2 {
      margin-bottom: 22px;
    }
    p {
      margin-bottom: 35px;
      max-width: 800px;
    }
  }
  .plansBlock {
    display: flex;

    h2 {
      margin-bottom: 22px;
    }

    .planBlock {
      width: 50%;

      &:first-child {
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }

}
