.heroBlock {
  margin-top: 30px;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px dashed var(--secondary-color);

  h1 {
    margin-bottom: 22px;
  }
  p {
    max-width: 700px;
    margin-bottom: 22px;
  }
  h2 {
    a {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}
