@import "variables";

* {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-color);
  text-underline-offset: 0.25em;
  box-sizing: border-box;
}

html {
  background: var(--primary-color);
  min-width: 360px;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
}

main {
  flex: 1;
  max-width: 1084px;
  padding: 0 30px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

@keyframes bgAnim {
  0% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  50% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-45deg) rotateY(-45deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  100% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
}

.bgAnimation {
  position: fixed;
  z-index: -1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  svg {
    min-width: fit-content;
    max-height: 100vh;
    animation: bgAnim 30s infinite linear;
  }
}

ul, li {
  list-style: none;
}

h1 {
  font-size: 36px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

p {
  font-size: 20px;
  line-height: 125%;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 120%;
  }
}

a {
  text-decoration: none;
}
