.header {
  width: 100%;
  max-width: 1084px;
  padding: 30px;
  display: flex;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
    transition: var(--transition);

    &:hover {
      opacity: 0.6;
      transition: var(--transition);
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    li {
      margin-left: 25px;
    }

    .loginBlock {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        padding: 3px 10px 0 10px;
        min-height: 30px;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
}
